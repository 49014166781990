export default class Contato {
    constructor({
        descricao,
        tipoEntregaCorrespondenciaId,
        tipoContatoId,
        nome,
        principal = false
    }) {
        this.descricao = descricao
        this.tipoEntregaCorrespondenciaId = tipoEntregaCorrespondenciaId
        this.tipoContatoId = tipoContatoId
        this.principal = principal
        this.nome = nome
    }
}