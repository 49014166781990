<template>
  <div>
    <modal
      id="modalAdicionarPrecos"
      :exibir="exibir"
      @fechar="fecharModal"
      :titulo="titulo"
      :campos="$refs"
      :ocultarRodape="true"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <tabela-selecionar-preco
              :form="form"
              :precos="precos"
              :precosContrato="precosContrato"
              @fechar="fecharModal"
              @precoSelecionado="precoSelecionado"
            />
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/modal/Modal.vue';

import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

import TabelaSelecionarPreco from '@/views/academico/contratos/components/TabelaSelecionarPreco';
import PrecoService from '@/common/services/precos/preco.service';

export default {
  name: 'ModalAdicionarPrecos',
  components: {
    Modal,
    TabelaSelecionarPreco,
  },
  props: {
    form: { type: Object, default: Object },
    precosContrato: { type: Array, default: Array },
    exibir: { type: Boolean, default: true },
    precos: { type: Array },
  },
  data() {
    return {
      formaPagamento: null,
      listaPreco: [],
    };
  },
  watch: {
    'form.grupoTurmaId': 'getPrecosPorGrupoTurmaCurso',
  },
  computed: {
    titulo() {
      return !this.item
        ? this.$t('PRECOS.ADICIONAR_PRECO')
        : this.$t('GERAL.EDITAR');
    },
  },
  methods: {
    getPrecosPorGrupoTurmaCurso() {
      const precoGrupoTurma = {
        anoLetivoId: this.form.anoLetivoId,
        grupoTurmaId: this.form.grupoTurmaId,
        cursoId: this.form.cursoId
      };

      this.$store.dispatch(START_LOADING);
      PrecoService.buscarPrecosPorGrupoTurmaCurso(precoGrupoTurma)
        .then(({ data }) => {
          data.forEach((x) => this.precos.push(x));
        })
        .catch((err) => {
          this.precosContrato = [];
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    precoSelecionado(e) {
      this.$emit('precosSelecionados', e);
    },
    fecharModal() {
      this.$emit('fechar');
    },
  },
};
</script>
