import ApiService from '@/common/api/api.service';

let apiBasePath = 'Desconto';
const DescontosService = {
  // FUNÇÕES CRUD:
  async criar(form) {
    let { data } = await ApiService.post(`${apiBasePath}`, form);
    return data;
  },
  async editar(form) {
    let { data } = await ApiService.put(`${apiBasePath}`, form);
    return data;
  },
  async excluir(aulaId) {
    let { data } = await ApiService.delete(`${apiBasePath}/${aulaId}`);
    return data;
  },
  // FUNÇÕES DE OBTENÇÃO DE DADOS:
  async obterTodos(form) {
    let { data } = await ApiService.get(`${apiBasePath}`, form);
    return data;
  },
  async obterTodosPorMatricula(matriculaId) {
    let { data } = await ApiService.get(`${apiBasePath}/desconto-por-matricula/${matriculaId}`);
    return data;
  },
  async realizarImpressaoRelatorioDescontosParaContratos(params) {
    let { data } = await ApiService.get(
      `${apiBasePath}/imprimir-relatorio-desconto-para-contrato`,
      {
        params: {
          anoLetivoId: params.anoLetivoId,
          tipoContrato: params.tipoContrato,
          status: params.status,
          layout: params.layout,
          descontoId: params.descontoId
        },
      }
    );
    return data;
  },
  async obterDescontosPorFiltroPaginado(expressao, paginacao) {
    let { data } = await ApiService.get(
      `${apiBasePath}/listar-por-paginacao`,
      { params: { expressao: expressao.descricao, ...paginacao } }
    );
    return data;
  },
};

export default DescontosService;
