export default class Matricula {
  constructor({
    id,
    pessoaId,
    observacao,
    idiomaId,
    idiomaDescricao,
    periodoLetivoId,
    descontos,
    vinculos = []
  }) {
    this.id = id;
    this.pessoaId = pessoaId;
    this.observacao = observacao;
    this.idiomaId = idiomaId;
    this.idiomaDescricao = idiomaDescricao;
    this.periodoLetivoId = periodoLetivoId;
    this.descontos = descontos;
    this.vinculos = vinculos;
  }
}
