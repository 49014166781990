<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <slot name="title" />
      <span />
      <b-button
        variant="primary"
        class="mb-3"
        @click="abrirModalAdicionarDesconto"
        v-if="!desativarForm"
      >
        {{ $t('ACADEMICO.ADICIONAR_DESCONTO') }}
      </b-button>
    </div>
    <b-table
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      :items="descontosLocal"
      :fields="campos"
      show-empty
      responsive
      striped
      hover
    >
      <template #cell(valor)="item">
        {{
          item.item.valor == 0
            ? `${item.item.percentual}%`
            : formatarReal(item.item.valor)
        }}
      </template>
      <template #cell(remover)="item">
        <b-button
          variant="secondary"
          class=""
          @click="removerDesconto(item.item)"
        >
          {{ $t('ACADEMICO.REMOVER') }}
        </b-button>
      </template>
    </b-table>

    <!-- MODAL -->
    <modal-adicionar-descontos
      :form="form"
      :descontos="descontos"
      :exibir="exibirModalAdicionarDesconto"
      @fechar="fecharModalAdicionarDesconto"
    />
  </div>
</template>

<script>
// AUX & HELPES
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// SERVICES
import DescontosService from '@/common/services/descontos/descontos.service';

//COMPONENTS
import ModalAdicionarDescontos from '@/views/academico/aditivo-contratos/components/modais/ModalAdicionarDescontos';

export default {
  name: 'TabelaDescontosAdicionados',
  components: {
    ModalAdicionarDescontos,
  },
  props: {
    form: { type: Object, default: Object },
    desativarForm: { type: Boolean, default: false },
  },

  data() {
    return {
      exibirModalAdicionarDesconto: false,
      descontos: [],
      descontosLocal: [],
    };
  },
  mounted() {
    this.getDescontos();
    if (!this.$route.query.id) {
      this.getDescontosMatricula();
    }
  },
  computed: {
    campos() {
      const c = [
        {
          key: 'descricao',
          sortable: true,
          label: this.$t('PRECOS.DESCRICAO'),
        },
        {
          key: 'valor',
          sortable: true,
          label: this.$t('PRECOS.VALOR'),
        },
      ];

      if (!this.desativarForm) {
        c.push({
          key: 'remover',
          label: this.$t('GERAL.REMOVER'),
        });
      }

      return c;
    },
  },
  watch: {
    'form.descontos': {
      handler(value) {
        this.descontosLocal = value;
      },
      deep: true,
    },
  },
  methods: {
    // FUNCOES OBTER DADOS
    getDescontos() {
      this.$store.dispatch(START_LOADING);
      DescontosService.obterTodos()
        .then(({ data }) => {
          this.descontos = data;
        })
        .catch((err) => {
          this.descontos = [];
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    // somente quando for no criar do contrato
    getDescontosMatricula() {
      this.$store.dispatch(START_LOADING);
      DescontosService.obterTodosPorMatricula(this.form.matriculaId)
        .then(({ data }) => {
          this.form.descontos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    // FUNCOES DADOS
    removerDesconto(item) {
      const indexDoItem = this.form.descontos.findIndex(
        (desconto) => desconto.id === item.id
      );
      if (indexDoItem !== -1) {
        this.form.descontos.splice(indexDoItem, 1);
      }
    },

    /// FUNCOES MODAL
    abrirModalAdicionarDesconto() {
      this.exibirModalAdicionarDesconto = true;
    },

    fecharModalAdicionarDesconto() {
      this.exibirModalAdicionarDesconto = false;
    },

    // FUNCOES AUX
    formatarReal(value) {
      return helpers.formatarReal(value);
    },
  },
};
</script>
