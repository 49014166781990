<template>
  <modal
    id="modalAdicionarDesconto"
    :exibir="exibir"
    @fechar="fecharModal"
    :titulo="titulo"
    :campos="$refs"
    :ocultarRodape="true"
  >
    <div class="container-fluid">
      <div>
        <b-table
          ref="selectableTable"
          class="limit-overflow"
          head-variant="light"
          :fields="campos"
          :items="descontosFiltrados"
          @row-selected="adicionarDesconto"
          select-mode="multi"
          responsive="sm"
          selectable
          striped
        >
          <template #cell(valor)="item">
            {{
              item.item.valor == 0
                ? `${item.item.percentual}%`
                : formatarReal(item.item.valor)
            }}
          </template>
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <i class="blue text-md fas fa-check-square"></i>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <i class="text-md far fa-square"></i>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
        </b-table>

        <div
          class="text-danger text-center my-4"
          v-if="descontosFiltrados.length === 0 && form.precos.length > 0"
        >
          <span> {{ $t('ACADEMICO.TODOS_OS_PRECOS_FORAM_ADICIONADOS') }} </span>
        </div>

        <div class="d-flex justify-content-between w-100 text-right mt-3">
          <b-button variant="secondary" @click="fecharModal">
            {{ $t('GERAL.FECHAR') }}
          </b-button>
          <b-button
            class="ml-2"
            variant="primary"
            @click="confirmarSelecaoDeDescontos"
          >
            {{ $t('GERAL.ADICIONAR') }}
          </b-button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
// aux e helpers
import helpers from '@/common/utils/helpers';

// components
import Modal from '@/components/modal/Modal.vue';

export default {
  name: 'ModalAdicionarDesconto',
  components: {
    Modal,
  },
  props: {
    form: { type: Object, default: Object },
    descontos: { type: Array },
    exibir: { type: Boolean, default: true },
  },
  data() {
    return {
      campos: [
        {
          key: 'selected',
          label: this.$t('GERAL.SELECIONADO'),
        },
        {
          key: 'descricao',
          sortable: true,
          label: this.$t('PRECOS.DESCRICAO'),
        },
        {
          key: 'valor',
          sortable: true,
          label: this.$t('PRECOS.VALOR'),
        },
      ],
      descontosSelecionados: [],
      descontosFiltrados: [],
    };
  },
  computed: {
    titulo() {
      return !this.item
        ? this.$t('ACADEMICO.ADICIONAR_DESCONTO')
        : this.$t('GERAL.EDITAR');
    },
  },
  watch: {
    exibir(novoValor) {
      if (novoValor) {
        this.filtrarDescontos();
      }
    },
  },
  methods: {
    adicionarDesconto(items) {
      this.descontosSelecionados = items;
    },

    confirmarSelecaoDeDescontos() {
      this.form.descontos.push(...this.descontosSelecionados);
      this.fecharModal();
    },

    filtrarDescontos() {
      this.descontosFiltrados = this.descontos.filter((desconto) => {
        return !this.form.descontos.some((d) => d.id === desconto.id);
      });
    },

    fecharModal() {
      this.$emit('fechar');
    },

    // FUNCOES AUX
    formatarReal(value) {
      return helpers.formatarReal(value);
    },
  },
};
</script>

