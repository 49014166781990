export default class InstituicaoEnsino {
    constructor({
      indentificacao,
      contatos = [],
      enderecos = [],
      razaoSocial,

    }) {
      this.indentificacao = indentificacao;
      this.contatos = contatos;
      this.enderecos = enderecos;
      this.razaoSocial = razaoSocial;
    }
  }