export default class Contrato {
  constructor({
    anoLetivoId = '',
    codigoContrato,
    cursoId = '',
    grupoTurmaId = '',
    idioma,
    matriculaId,
    precos,
    status,
    statusContrato,
    statusDescricao,
    tipoContrato,
    descontos,
  }) {
    this.anoLetivoId = anoLetivoId;
    this.codigoContrato = codigoContrato;
    this.cursoId = cursoId;
    this.grupoTurmaId = grupoTurmaId;
    this.idioma = idioma;
    this.matriculaId = matriculaId;
    this.precos = precos;
    this.status = status;
    this.statusContrato = statusContrato;
    this.statusDescricao = statusDescricao;
    this.tipoContrato = tipoContrato;
    this.descontos = descontos
  }
}
