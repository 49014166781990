<template>
  <div>
    <b-table
      head-variant="light"
      :fields="campos"
      :items="planosParcelamento"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      responsive
      show-empty
      striped
      hover
    />
  </div>
</template>

<script>
import helpers from '@/common/utils/helpers';

export default {
  props: {
    form: { type: Object, default: Object },
    planosParcelamento: { type: Array, default: Array },
    // era Object
  },
  data() {
    return {
      campos: [
        {
          key: 'dataVencimento',
          sortable: true,
          label: this.$t('ACADEMICO.DATA_VENCIMENTO'),
        },
        {
          key: 'competencia',
          sortable: true,
          label: this.$t('ACADEMICO.COMPETENCIA'),
        },
        {
          key: 'valor',
          sortable: true,
          label: this.$t('ACADEMICO.VALOR'),
          formatter: (value) => {
            return value ? helpers.formatarReal(value) : '-';
          },
        },
        {
          key: 'parcela',
          sortable: true,
          label: this.$t('ACADEMICO.PARCELA'),
        },
        {
          key: 'descricao',
          label: this.$t('ACADEMICO.HISTORICO'),
        },
      ],
      selected: {},
    };
  },
};
</script>
