export default class MotivoCancelamento {
    constructor({
        instituicaoEnsinoId,
        motivoMovimentacao,
        motivoCancelamento,
        contratoId
    }) {
        this.instituicaoEnsinoId = instituicaoEnsinoId;
        this.motivoMovimentacao = motivoMovimentacao;
        this.motivoCancelamento = motivoCancelamento;
        this.contratoId = contratoId;
    }
}