import ApiService from "@/common/api/api.service";

let apiBasePath = 'GrupoTurma'
const GrupoTurmaService = {
  async buscarPorCursoIdAnoLetivoId (cursoId, anoLetivoId) {
    let { data } = await ApiService.get(`grupoturma/obter-por-curso-id?cursoId=${cursoId}&anoLetivoId=${anoLetivoId}`);
    return data;
  },
  async buscarPorId (id) {
    let { data } = await ApiService.get(`${apiBasePath}/${id}`);
    return data;
  },
  async criar (form) {
    let { data } = await ApiService.post(`${apiBasePath}`, form);
    return data;
  },
  async editar (form) {
    let { data } = await ApiService.put(`${apiBasePath}`, form);
    return data;
  },
  async excluir (id) {
    return await ApiService.delete(`${apiBasePath}/${id}`);
  },
};

export default GrupoTurmaService;
