<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <slot name="title" />
      <span />
      <b-button
        v-if="!this.desativarForm"
        :disabled="desabilitarBotao"
        variant="primary"
        @click="abrirModalAdicionarPrecos"
      >
        {{ $t('PRECOS.ADICIONAR_PRECO') }}
      </b-button>
    </div>
    <b-table
      head-variant="light"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      :items="precosContrato"
      :fields="campos"
      show-empty
      responsive
      striped
      hover
    >
      <template #cell(formaPagamento)="precosContrato">
        <input-select
          ref="tipoFormaPagamento"
          v-if="!desativarForm"
          :options="precosContrato.item.formasPagamento"
          value-field="id"
          text-field="descricao"
          v-model="form.precos[precosContrato.index].formaPagamentoId"
          required
          :disabled="precosContrato.item.formasPagamento.length === 1"
        />
        <span v-else>
          {{ precosContrato.item.formaPagamentoSelecionadaDescricao }}
        </span>
      </template>
      <template #cell(origem)="origem">
        {{ origem.item.aditivoContratoId ? 'Aditivo' : 'Contrato' }}
      </template>

      <template v-if="!this.desativarForm" #cell(removerPreco)="precosContrato">
        <b-button variant="secondary" @click="removerPreco(precosContrato)">
          {{ $t('ACADEMICO.REMOVER') }}
        </b-button>
      </template>
    </b-table>
    <modal-adicionar-precos
      :form="form"
      :precosContrato="precosContrato"
      :precos="precos"
      @precosSelecionados="precosSelecionados"
      :exibir="mostrarModalAdicionarPrecos"
      @fechar="fecharModalAdicionarPrecos"
    />
  </div>
</template>

<script>
import helpers from '@/common/utils/helpers';
import ModalAdicionarPrecos from './ModalAdicionarPrecos';
import { InputSelect } from '@/components/inputs';

export default {
  name: 'TabelaPrecos',
  components: {
    ModalAdicionarPrecos,
    InputSelect,
  },
  props: {
    form: { type: Object, default: Object },
    precosContrato: { type: Array, default: Array },
    desativarForm: { type: Boolean, default: false },
    desabilitarBotao: { type: Boolean, default: false },
  },
  data() {
    return {
      mostrarModalAdicionarPrecos: false,
      precoRetiradoTabela: {},
      precos: [],
    };
  },
  computed: {
    campos() {
      return [
        {
          key: 'descricao',
          label: this.$t('PRECOS.DESCRICAO'),
        },
        {
          key: 'valorBase',
          label: this.$t('PRECOS.VALOR'),
          formatter: (value) => {
            return value ? helpers.formatarReal(value) : '-';
          },
        },
        {
          key: 'formaPagamento',
          label: this.$t('ACADEMICO.FORMA_PAGAMENTO'),
        },
        this.desativarForm && {
          key: 'origem',
          label: this.$t('PRECOS.ORIGEM'),
        },
        !this.desativarForm && {
          key: 'removerPreco',
          label: this.$t('PRECOS.REMOVER_PRECO'),
        },
      ];
    },
  },
  methods: {
    precosSelecionados(e) {
      this.precos = e.precosSelecionados;
    },
    abrirModalAdicionarPrecos() {
      this.mostrarModalAdicionarPrecos = true;
    },
    fecharModalAdicionarPrecos() {
      this.mostrarModalAdicionarPrecos = false;
    },
    removerPreco(item) {
      this.precos.push(item.item);
      this.$emit('remover-preco', item.item);
    },
  },
};
</script>
