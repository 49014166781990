export default class Segmentacao {
    constructor({
        anoLetivoId,
        descricao,
        dataInicio,
        dataFim,
        lancamentoNota = false,
        lancamentoFrequencia = false,
        lancamentoRecuperacao = false
    }) {
        this.descricao = descricao;
        this.dataInicio = dataInicio;
        this.anoLetivoId = anoLetivoId;
        this.dataFim = dataFim;
        this.lancamentoNota = lancamentoNota;
        this.lancamentoFrequencia = lancamentoFrequencia;
        this.lancamentoRecuperacao = lancamentoRecuperacao;
    }
}