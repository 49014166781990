export default class Pessoa {
  constructor({
    contatos = [],
    cpf,
    dataExpedicao,
    dataNascimento,
    enderecos = [],
    estadoCivil,
    identidade,
    foto,
    fotoUpload,
    nome,
    nacionalidadeId,
    naturalidadeId,
    orgaoExpedidor,
    religiao,
    registroNascimento,
    codigo,
    sexo,
  }) {
    this.contatos = contatos;
    this.cpf = cpf;
    this.dataNascimento = dataNascimento;
    this.enderecos = enderecos;
    this.dataExpedicao = dataExpedicao;
    this.estadoCivil = estadoCivil;
    this.identidade = identidade;
    this.foto = foto;
    this.fotoUpload = fotoUpload;
    this.nome = nome;
    this.nacionalidadeId = nacionalidadeId;
    this.naturalidadeId = naturalidadeId;
    this.orgaoExpedidor = orgaoExpedidor;
    this.religiao = religiao;
    this.registroNascimento = registroNascimento;
    this.sexo = sexo;
    this.codigo = codigo;
  }
}
