export default class Aula {
    constructor({
        conteudo,
        descricao,
        data,
        turmaId,
        disciplinaId
    }) {
        this.conteudo = conteudo
        this.descricao = descricao
        this.data = data
        this.turmaId = turmaId
        this.disciplinaId = disciplinaId
    }
}