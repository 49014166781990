import ApiService from '@/common/api/api.service';

let apiBasePath = 'Preco';

const PrecoService = {
  // FUNÇÕES CRUD:
  async salvar(form) {
    let result = [];
    if (!form.id) result = await ApiService.post(`${apiBasePath}`, form);
    else result = await ApiService.put(`${apiBasePath}`, form);
    return result;
  },
  async criar(form) {
    let { data } = await ApiService.post(`${apiBasePath}`, form);
    return data;
  },
  async editar(form) {
    let { data } = await ApiService.put(`${apiBasePath}`, form);
    return data;
  },
  async excluir(id) {
    return await ApiService.delete(`${apiBasePath}/${id}`);
  },

  // FUNÇÕES OBTENÇÃO DE DADOS:
  async listar() {
    let { data } = await ApiService.get(`${apiBasePath}`);
    return data;
  },
  async buscarPrecosPorGrupoTurma(grupoTurmaId) {
    let { data } = await ApiService.get(
      `${apiBasePath}/grupo-turma/${grupoTurmaId}`
    );
    return data;
  },
  async buscarPrecosPorCurso(cursoAno) {
    const { anoLetivoId, cursoId } = cursoAno;

    let { data } = await ApiService.get(
      `${apiBasePath}/curso?CursoId=${cursoId}&AnoLetivoId=${anoLetivoId}`
    );
    return data;
  },
  async buscarPrecosPorGrupoTurmaCurso(precoGrupoTurma) {
    const { anoLetivoId, grupoTurmaId, cursoId } = precoGrupoTurma;

    let { data } = await ApiService.get(
      `${apiBasePath}/grupo-turma-curso?GrupoTurmaId=${grupoTurmaId}&cursoId=${cursoId}&AnoLetivoId=${anoLetivoId}`
    );
    return data;
  },
  async buscarPlanosPagamento(form) {
    let { data } = await ApiService.post(
      `${apiBasePath}/listar-plano-pagamento`,
      form
    );
    return data;
  },
  async buscarPorId(id) {
    let { data } = await ApiService.get(`${apiBasePath}/${id}`);
    return data;
  },
  async obterPrecosPorFiltroPaginado(expressao, paginacao) {
    let { data } = await ApiService.get(`${apiBasePath}/listar-por-paginacao`, {
      params: { ...expressao, ...paginacao },
    });
    return data;
  },
};

export default PrecoService;
