export default class Endereco {
    constructor({
       cep,
       logradouro,
       numero,
       complemento,
       bairro,
       principal,
       tipoEndereco,
       tipoEnderecoDescricao,
       municipioId,
       estadoId,
       paisId,
    }) {
        this.cep = cep;
        this.logradouro = logradouro;
        this.numero = numero;
        this.complemento = complemento;
        this.bairro = bairro;
        this.principal = principal;
        this.tipoEndereco = tipoEndereco;
        this.municipioId = municipioId;
        this.estadoId = estadoId;
        this.paisId = paisId;
        this.tipoEnderecoDescricao = tipoEnderecoDescricao;
    }
}