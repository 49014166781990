<template>
  <div>
    <div class="limit-overflow">
      <b-table
        head-variant="light"
        :empty-text="$t('GERAL.NENHUM_REGISTRO')"
        :items="precosFiltrados"
        :fields="campos"
        select-mode="multi"
        show-empty
        responsive="sm"
        ref="selectableTable"
        selectable
        striped
        @row-selected="onRowSelected"
      >
        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <i class="blue text-md fas fa-check-square"></i>
            <span class="sr-only">Selected</span>
          </template>
          <template v-else>
            <i class="text-md far fa-square"></i>
            <span class="sr-only">Not selected</span>
          </template>
        </template>
      </b-table>
    </div>

    <div class="text-right d-flex space-between mt-4">
      <b-button variant="secondary" class="" @click="fecharModal">
        {{ $t('GERAL.FECHAR') }}
      </b-button>
      <b-button class="ml-2" variant="primary" @click="selecionaPreco">
        {{ $t('GERAL.SELECIONE') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import helpers from '@/common/utils/helpers';

export default {
  name: 'TabelaSelecionarPreco',
  props: {
    form: { type: Object, default: Object },
    precos: { type: Array, default: Array },
    precosContrato: { type: Array, default: Array },
  },
  data() {
    return {
      campos: [
        {
          key: 'selected',
          label: this.$t('GERAL.SELECIONADO'),
        },
        {
          key: 'descricao',
          sortable: true,
          label: this.$t('PRECOS.DESCRICAO'),
        },
        {
          key: 'valorBase',
          sortable: true,
          label: this.$t('PRECOS.VALOR'),
          formatter: (value) => {
            return value ? helpers.formatarReal(value) : '-';
          },
        },
      ],
      selected: {},
    };
  },
  computed: {
    precosFiltrados() {
      return this.precos.filter((item, index, array) => {
        return (
          !this.precosContrato.some((contrato) => contrato.id === item.id) &&
          array.findIndex((elemento) => elemento.id === item.id) === index
        );
      });
    },
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    selecionaPreco() {
      this.selected.map((item) => {
        this.form.precos.push(item.id);
        this.precosContrato.push(item);
      });

      const precosSelecionados = this.precos.filter(
        ({ id }) => id !== this.selected.id
      );

      this.$emit('precoSelecionado', {
        precosSelecionados,
      });
      this.fecharModal();
    },
    fecharModal() {
      this.$emit('fechar');
    },
  },
};
</script>
