export default class BaixarTitulo {
  constructor({
    valor,
    juros,
    total,
    desconto,
    dataVencimento,
    dataPagamento,
    tipoFormaPagamento,
    contaId,
    valorDemonstrativo,
    totalLocal,
    id,
   
  }) {
    this.valor = valor;
    this.juros = juros;
    this.total = total;
    this.desconto = desconto;
    this.dataVencimento = dataVencimento;
    this.tipoFormaPagamento = tipoFormaPagamento;
    this.contaId = contaId;
    this.dataPagamento = dataPagamento;
    this.totalLocal = totalLocal;
    this.id = id;
    this.valorDemonstrativo = valorDemonstrativo;
    
  }
}
