<template>
  <div class="mb-4">
    <topbar
      :titulo="$t('ACADEMICO.CONTRATO')"
      :subtitulo="descricaoTela"
      :breadcrumbs="breadcrumbs"
    >
      <template #header>
        <b-button variant="secondary" class="mr-2" @click="retornar()">
          {{ $t('GERAL.VOLTAR') }}
        </b-button>
      </template>
    </topbar>
    <b-tabs>
      <!-- tab 1 -->
      <b-tab :title="$t('GERAL.DADOS_GERAIS')" class="pt-4" active>
        <b-row align-v="start">
          <b-col cols="3">
            <input-text
              :disabled="true"
              v-model="codigoMatricula"
              ref="codigoMatricula"
              :label="$t('ACADEMICO_MATRICULAS.MATRICULA')"
              required
            />
          </b-col>
          <b-col cols="3">
            <input-text
              :disabled="true"
              v-model="nomeAluno"
              ref="aluno"
              :label="$t('ACADEMICO_MATRICULAS.ALUNO')"
              required
            />
          </b-col>
          <b-col cols="3" v-if="desabilitar.form">
            <input-text
              :disabled="desabilitar.form"
              v-model="form.codigoContrato"
              ref="codigoContrato"
              :label="'Código contrato'"
              required
            />
          </b-col>
          <b-col cols="3" :key="opcoes.tipoContratos.id">
            <input-select-search
              :isDisabled="desabilitar.form"
              ref="form.tipoContrato"
              :options="opcoes.tipoContratos"
              v-model="form.tipoContrato"
              :label="$t('ACADEMICO.TIPO_CONTRATO')"
              required
            />
          </b-col>
          <b-col cols="3">
            <input-select
              :disabled="desabilitar.form"
              ref="form.anoLetivoId"
              :options="opcoes.anosLetivos"
              v-model="form.anoLetivoId"
              :label="$t('GERAL.ANO_LETIVO')"
              valueField="id"
              textField="ano"
              required
            />
          </b-col>
          <b-col cols="3">
            <input-select-search
              :isDisabled="desabilitar.curso || desabilitar.form"
              ref="form.cursoId"
              :options="opcoes.cursos"
              v-model="form.cursoId"
              :label="$t('GERAL.CURSO')"
              required
            />
          </b-col>
          <b-col cols="3">
            <input-select
              ref="form.grupoTurmaId"
              :options="opcoes.grupoTurmas"
              v-model="form.grupoTurmaId"
              :label="$t('GERAL.GRUPO_TURMA')"
              valueField="id"
              textField="descricao"
              :disabled="desabilitar.grupoTurma || desabilitar.form"
              required
            />
          </b-col>
          <b-col cols="3">
            <input-select-search
              :isDisabled="desabilitar.form"
              ref="form.idioma"
              :options="opcoes.idiomas"
              v-model="form.idioma"
              :label="$t('ACADEMICO_MATRICULAS.IDIOMA')"
            />
          </b-col>
          <b-col cols="3">
            <input-select
              :disabled="desabilitar.form"
              :options="opcoes.statusContrato"
              :label="$t('ACADEMICO_MATRICULAS.STATUS_CONTRATO')"
              v-model="form.statusContrato"
            />
          </b-col>
        </b-row>

        <!-- Preços -->
        <hr />
        <b-row>
          <b-col cols="12">
            <tabela-precos
              :desabilitarBotao="!form.grupoTurmaId"
              :desativarForm="desabilitar.form"
              :precosContrato="precosContrato"
              :form="form"
              @remover-preco="removerPreco"
            >
              <template v-slot:title>
                <h4>
                  {{ $t('ACADEMICO.PRECOS') }}
                </h4>
              </template>
            </tabela-precos>
          </b-col>
        </b-row>

        <!-- Descontos -->
        <hr />
        <b-row>
          <b-col cols="12">
            <tabela-descontos-adicionados
              :desativarForm="desabilitar.form"
              :form="form"
            >
              <template v-slot:title>
                <h4>
                  {{ $t('ACADEMICO.DESCONTOS') }}
                </h4>
              </template>
            </tabela-descontos-adicionados>
          </b-col>
        </b-row>

        <!-- Valores planos de parcelamento -->
        <hr />
        <b-row v-if="!desabilitar.form" class="align-items-center">
          <b-col cols="9" class="align-items-center">
            <h4>
              {{ $t('ACADEMICO.VALORES_PLANO_PARCELAMENTO') }}
            </h4>
          </b-col>

          <b-col cols="3">
            <input-text
              :disabled="desabilitar.form"
              v-model="valorTotalContrato"
              :label="$t('PRECOS.VALOR')"
            />
          </b-col>

          <hr />
          <b-col cols="12">
            <tabela-valores-plano-parcelamento
              :visualizar="!desabilitar.form"
              :form="form"
              :planosParcelamento="planosParcelamento"
            />
          </b-col>
        </b-row>
      </b-tab>

      <!-- aba 2 -->
      <b-tab
        :title="$t('ACADEMICO.SIMULACAO_PARCELAS')"
        class="pt-4"
        v-if="desabilitar.form"
      >
        <!-- Listagem de títulos e títulos ocorrência -->
        <listagem-contas-a-receber :itens="contasAReceber" />
      </b-tab>
    </b-tabs>

    <b-row>
      <b-col cols="12" class="text-right">
        <b-button
          v-if="this.id"
          variant="info"
          class="mr-2"
          @click="baixarContrato"
        >
          {{ $t('ACADEMICO.BAIXAR_CONTRATO') }}
        </b-button>
        <b-button
          v-if="!this.$route.query.visualizar"
          variant="primary"
          @click="salvar"
          >{{ $t('GERAL.SALVAR') }}</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
// Utils & Aux:
import VerificacaoPreenchimento from '@/components/mixins/verificacao-preenchimento';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';
import pdf from '@/common/utils/pdf';

// Services:
import ContratoService from '@/common/services/contratos/contratos.service';
import MatriculaService from '@/common/services/matriculas/matriculas.service';
import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';
import PrecoService from '@/common/services/precos/preco.service';
import GrupoTurmaService from '@/common/services/grupoTurma/grupo-turma.service';
import CursoService from '@/common/services/academico/curso.service';
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service';
import ContasReceberService from '@/common/services/conta-receber/contaReceber.service';
import DescontoService from '@/common/services/descontos/descontos.service.js';

// Models:
import { Contrato } from '@/common/models';

// Components:
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';
import { InputSelect, InputSelectSearch, InputText } from '@/components/inputs';
import TabelaPrecos from '@/views/academico/contratos/components/TabelaPrecos.vue';
import TabelaDescontosAdicionados from '@/views/academico/contratos/components/TabelaDescontosAdicionados';
import TabelaValoresPlanoParcelamento from '@/views/academico/contratos/components/TabelaValoresPlanoParcelamento.vue';
import ListagemContasAReceber from '@/views/academico/aditivo-contratos/components/tabelas/ListagemContasAReceber';

export default {
  name: 'Formulario',
  mixins: [VerificacaoPreenchimento],
  components: {
    Topbar,
    InputSelect,
    InputText,
    InputSelectSearch,
    TabelaPrecos,
    TabelaValoresPlanoParcelamento,
    ListagemContasAReceber,
    TabelaDescontosAdicionados,
  },
  data() {
    return {
      id: this.$route.query.id,
      nomeAluno: '',
      codigoMatricula: '',
      form: new Contrato({
        status: 'Ativo',
        matriculaId: this.$route.query.matriculaId,
        precos: [],
        statusContrato: 0,
        descontos: [],
      }),
      desabilitar: {
        form: false,
        grupoTurma: true,
        curso: true,
      },
      opcoes: {
        anosLetivos: [],
        cursos: [],
        idiomas: [],
        grupoTurmas: [],
        tipoContratos: [],
        statusContrato: [],
      },
      paginacao: { numeroDaPagina: 1, tamanhoDaPagina: 30 },
      pdf: {},
      planosParcelamento: [],
      precosContrato: [],
      status: true,
      statusContratos: [],
      valorTotalContrato: 0,
      contasAReceber: [],
      salvo: false,
      breadcrumbs: [
        { titulo: this.$t('ACADEMICO.ACADEMICO') },
        { titulo: this.$t('ACADEMICO_MATRICULAS.CONTRATOS') },
        {
          titulo: !this.id
            ? this.$t('GERAL.CADASTRAR')
            : this.$route.query.visualizar
            ? this.$t('GERAL.VISUALIZAR')
            : this.$t('GERAL.EDITAR'),
        },
      ],
    };
  },
  computed: {
    descricaoTela() {
      if (this.$route.query.visualizar) {
        return this.$t('ACADEMICO.CONTRATO_DESCRICAO_VISUALIZAR');
      }
      return this.$t('ACADEMICO.CONTRATO_DESCRICAO_CRIAR_EDITAR');
    },
  },
  mounted() {
    if (this.$route.query) {
      this.codigoMatricula = this.$route.query.codigoMatricula;
      this.form.matriculaId = this.$route.query.matriculaId;
      this.buscarMatriculaPorId();
    }

    if (this.$route.query.visualizar) {
      this.desabilitar.form = true;
    }

    if (!this.$route.query.visualizar && this.$route.query.id) {
      this.desabilitar.grupoTurma = false;
      this.desabilitar.curso = false;
    }

    if (this.id) {
      this.buscarPorId();
      this.pegarContasAReceber();
    }

    this.getIdiomas();
    this.getTipoContratos();
    this.getAnosLetivo();
    this.getStatusContratos();
    this.getStatusContratoEnum();
    this.getDescontosDaMatricula();
  },
  watch: {
    'form.anoLetivoId'(anoLetivoId, oldValue) {
      if (anoLetivoId) {
        this.getCursos();
      } else {
        this.opcoes.cursos = [];
        this.opcoes.grupoTurmas = [];
        this.desabilitar.cursos = true;
        this.desabilitar.grupoTurma = true;
      }
      if (oldValue) {
        this.form.cursoId = null;
        this.form.grupoTurmaId = null;
      }
    },
    'form.cursoId'(cursoId, oldValue) {
      if (cursoId) {
        this.getGrupoTurma();
      } else {
        this.opcoes.grupoTurmas = [];
        this.desabilitar.grupoTurma = true;
      }
      if (oldValue) {
        this.form.grupoTurmaId = null;
      }
    },
    'form.grupoTurmaId'(grupoTurmaId) {
      if (grupoTurmaId) {
        this.getPrecos();
      } else {
        this.precosContrato = [];
        this.form.precos = [];
      }
    },
    status() {
      this.status
        ? (this.form.status = 'Ativo')
        : (this.form.status = 'Inativo');
    },
    precosContrato: {
      handler(precos) {
        let precosLocal = [];
        precos.forEach((item) => {
          precosLocal.push({
            precoId: item.id,
            formaPagamentoId: this.id
              ? item.formaPagamentoSelecionadaId
              : item.formasPagamento[0]?.id,
            id: item.id,
            anoLetivoId: this.form.anoLetivoId,
          });
        });
        this.form.precos = precosLocal;
        this.calcularValorTotalContrato();
        this.valorTotalContrato = helpers.formatarReal(this.valorTotalContrato);
      },
      deep: true,
      immediate: true,
    },

    'form.precos': {
      handler() {
        this.getPlanosParcelamento();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    buscarPorId() {
      this.$store.dispatch(START_LOADING);
      ContratoService.buscarPorId(this.id)
        .then(({ data }) => {
          this.form = { ...data };
          // TODO: depois de concluir o back, retirar esse if
          if (data?.descontos == undefined) {
            this.form.descontos = [];
          }
          this.precosContrato = data.precos;
          if (this.form.matriculaId) {
            this.buscarMatriculaPorId();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    pegarContasAReceber() {
      this.$store.dispatch(START_LOADING);
      ContasReceberService.obterPorFiltroLista({ contratoId: this.id })
        .then(({ data }) => {
          this.contasAReceber = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getCursos() {
      if (this.form.anoLetivoId.length === 0) {
        return;
      }
      if (!this.id) {
        this.form.cursoId = '';
        this.form.grupoTurmaId = '';
        this.desabilitar.grupoTurma = true;
        this.precosContrato = [];
      }
      this.$store.dispatch(START_LOADING);
      CursoService.obterPorAnoLetivo(this.form.anoLetivoId)
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.cursos = data;
          if (!this.id) {
            this.desabilitar.curso = false;
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getGrupoTurma() {
      if (this.form.anoLetivoId.length == 0 || this.form.cursoId.length == 0) {
        return;
      }
      if (!this.id) {
        this.precosContrato = [];
        this.form.grupoTurmaId = null;
      }
      this.$store.dispatch(START_LOADING);
      GrupoTurmaService.buscarPorCursoIdAnoLetivoId(
        this.form.cursoId,
        this.form.anoLetivoId
      )
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.grupoTurmas = data;
          if (!this.id) {
            this.desabilitar.grupoTurma = false;
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getPrecos() {
      if (
        this.form.anoLetivoId &&
        this.form.grupoTurmaId &&
        !this.id &&
        this.form.cursoId
      ) {
        this.getPrecosPorGrupoTurmaCurso();
      }
    },

    getStatusContratoEnum() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('status-contrato')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.statusContrato = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getIdiomas() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('idiomas')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.idiomas = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getTipoContratos() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-contratos')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.tipoContratos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getAnosLetivo() {
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.listar(this.paginacao)
        .then(({ data }) => {
          this.opcoes.anosLetivos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getStatusContratos() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('status-contrato')
        .then(({ data }) => {
          this.statusContratos = data;
          this.gerenciaStatusContrato();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getPrecosPorGrupoTurmaCurso() {
      const precoGrupoTurma = {
        anoLetivoId: this.form.anoLetivoId,
        grupoTurmaId: this.form.grupoTurmaId,
        cursoId: this.form.cursoId,
      };

      if (!this.id) {
        this.precosContrato = [];
      }

      this.$store.dispatch(START_LOADING);
      PrecoService.buscarPrecosPorGrupoTurmaCurso(precoGrupoTurma)
        .then(({ data }) => {
          this.precosContrato = data;
        })
        .catch((err) => {
          this.precosContrato = [];
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getPlanosParcelamento() {
      this.$store.dispatch(START_LOADING);
      PrecoService.buscarPlanosPagamento(this.form.precos)
        .then(({ data }) => {
          this.planosParcelamento = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getDescontosDaMatricula() {
      if (!this.$route.query.matriculaId) return;
      this.$store.dispatch(START_LOADING);
      DescontoService.obterTodosPorMatricula(this.$route.query.matriculaId)
        .then(({ data }) => {
          this.form.descontos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    // FUNÇÕES DE AÇÃO:

    gerenciaStatusContrato() {
      if (!this.id) {
        this.statusContratos.forEach((element) => {
          if (element.descricao == 'Em aprovação') {
            this.form.statusContrato = element.id;
            this.form.statusDescricao = element.descricao;
          }
        });
      }
    },

    calcularValorTotalContrato() {
      this.valorTotalContrato = 0;
      this.precosContrato.forEach((item) => {
        this.valorTotalContrato += item.valorBase;
      });
    },
    // tirar essas
    async mudaStatusContrato(descricao) {
      const respostaMudarStatus = await mensagem.confirmacao(
        null,
        this.$t('ACADEMICO.CONFIRMAR_ALTERACAO_STATUS').formatUnicorn({
          status: descricao,
        })
      );
      if (respostaMudarStatus.isConfirmed) {
        this.statusContratos.forEach((element) => {
          if (element.descricao == descricao) {
            this.form.statusContrato = element.id;
            this.form.statusDescricao = element.descricao;
          }
        });
        if (this.id) {
          this.salvar();
        }
      }
    },

    buscarMatriculaPorId() {
      if (this.form.matriculaId)
        MatriculaService.buscarPorId(this.form.matriculaId)
          .then(({ data }) => {
            this.codigoMatricula = data.codigoMatricula;
            this.nomeAluno = data.nome;
            if (!this.id) {
              this.form.idioma = data.idiomaId;
            }
          })
          .catch((err) => {
            mensagem.showErrors(err);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
    },

    baixarContrato() {
      this.$store.dispatch(START_LOADING);

      ContratoService.baixarPDF(this.id)
        .then(({ data }) => {
          if (data.documento) {
            //converte os bytes em array:
            let bytes = pdf.base64ToArrayBuffer(data.documento);

            // converte o array em blob
            let blob = new Blob([bytes], { type: 'application/pdf' });

            // faz download do PDF:
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Contrato.pdf';
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    salvar() {
      if (!this.validarFormulario()) return;
      //this.form.tipoContrato !== 285 -> tipo de contrato como 285 é igual a Atividade extraclasse
      if (!this.form.precos.length && this.form.tipoContrato !== 285) {
        return mensagem.warning(
          this.$t('GERAL.AVISO'),
          this.$t('ACADEMICO.CONTRATO_ADICIONE_PRECO')
        );
      }

      if (this.id) {
        return this.atualizar();
      }

      this.criar();
    },

    criar() {
      this.$store.dispatch(START_LOADING);
      ContratoService.salvar(this.form)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('ACADEMICO.CONTRATO_CADASTRADO')
          );
          this.salvo = true;
          this.$router.push({ name: 'contratos' });
        })
        .catch((err) => {
          this.salvo = false;
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    atualizar() {
      this.$store.dispatch(START_LOADING);
      ContratoService.atualizar(this.form)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('ACADEMICO.CONTRATO_CADASTRADO')
          );
          this.salvo = true;
          this.$router.push({ name: 'contratos' });
        })
        .catch((err) => {
          this.salvo = false;
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
    removerPreco(item) {
      const precosContrato = this.precosContrato.filter(
        (preco) => preco.id != item.id
      );
      this.precosContrato = precosContrato;
    },
    retornar() {
      this.$router.push({ name: 'contratos' });
    },
  },
};
</script>
<style>
.card-title.status {
  display: inline;
  font-size: 15px;
  margin-right: 10px;
}

.flex-col {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}

.ajuste-fino {
  margin-top: -11px;
}
</style>
