export default class PeriodoLetivo {
    constructor({
        ano,
        dataInicio,
        dataFim,
        dataInicioMatricula,
        dataFimMatricula,
        anoLetivoAntecessorId,
        segmentacoes,
        turmas,
        tipoAvaliacoes,
        flagCriarPeriodoFinanceiro,
        criarPeriodoFinanceiroRequest,
    }) {
        this.ano = ano;
        this.dataInicio = dataInicio;
        this.dataFim = dataFim;
        this.dataInicioMatricula = dataInicioMatricula;
        this.dataFimMatricula = dataFimMatricula;
        this.anoLetivoAntecessorId = anoLetivoAntecessorId;
        this.segmentacoes = segmentacoes;
        this.turmas = turmas;
        this.tipoAvaliacoes = tipoAvaliacoes;
        this.flagCriarPeriodoFinanceiro = flagCriarPeriodoFinanceiro;
        this.criarPeriodoFinanceiroRequest = criarPeriodoFinanceiroRequest;
    }
}