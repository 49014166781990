export default class Disciplina {
    constructor({
        descricao,
        disciplina,
        idioma,
        cargaHoraria
    }) {
        this.descricao = descricao
        this.disciplina = disciplina
        this.idioma = idioma
        this.cargaHoraria = cargaHoraria
    }
}