export default class Frequencia {
    constructor({
        presente,
        faltaJustificada,
        observacao,
        turmaId,
        disciplinaId,
        aulaId,
        matriculaId,
        cursoId,
        anoLetivoId,

    }) {
        this.presente = presente;
        this.faltaJustificada = faltaJustificada;
        this.observacao = observacao;
        this.turmaId = turmaId;
        this.disciplinaId = disciplinaId;
        this.aulaId = aulaId;
        this.matriculaId = matriculaId;
        this.cursoId = cursoId;
        this.anoLetivoId = anoLetivoId;
    }
}